import React, { Component } from "react";
import { connect } from "react-redux";
import kateIcon from "../assets/cpg_katepersonaicon.png";
import mattIcon from "../assets/matt_icon.png";
import Goals from "./Goals";
import styles from "./PersonaGoals.module.scss";

class PersonaGoals extends Component {
  constructor() {
    super();
    this.state = {
      isGoalsOpen: false
    };
  }
  handleClick = () => {
    this.setState({
      isGoalsOpen: true
    });
  };
  closeGoals = () => {
    this.setState({
      isGoalsOpen: false
    });
  };
  showGoals() {
    if (!this.state.isGoalsOpen) {
      return;
    }
    return (
      <Goals className={styles.goals} closeGoals={this.closeGoals}></Goals>
    );
  }
  render() {
    return (
      <div className={styles.wrapper}>
        <div
          className={`${styles.persona_icon} ${
            this.state.isGoalsOpen ? styles.selected : ""
          }`}
        >
          <img
            src={this.props.path === "auto" ? mattIcon : kateIcon}
            alt=""
          ></img>
          <div className={styles.shadow}></div>
        </div>
        {this.showGoals()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    goals: state.goals,
    selectedPreset: state.selectedPreset,
    path: state.path
  };
};

export default connect(mapStateToProps)(PersonaGoals);
