import anime from "animejs/lib/anime.es.js";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import {
  METRIC_ANIMATIONS_RUNNING,
  SHOW_HIDE_METRIC_GRAPH
} from "../actions/ActionTypes";
import { GetNumberOfSquareFor, GetTextColorForValue } from "../utils/colors";
import MetricChart from "./MetricChart";
import styles from "./MetricTile.module.scss";
const NO_SQUARE = 12;
class MetricTile extends Component {
  constructor(props) {
    super(props);
    this.valueRef = React.createRef();
    this.titleRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.squareContainerRef = React.createRef();
    this.wrapperChartRef = React.createRef();
    this.contentRef = React.createRef();
    this.metricChartRef = React.createRef();
  }

  runAnimations() {
    let percentageObj = {
      percentage: this.valueRef.current ? this.valueRef.current.innerText : "0%"
    };
    let duration = 400;
    let round = 10;

    if (this.props.metric.title.toLowerCase() === "conversions") {
      round = 1;
    }
    const animation = anime
      .timeline({
        targets: percentageObj,
        percentage: this.props.metric.value,
        easing: "easeInOutQuad",
        duration,
        round,
        update: () => {
          if (!this.valueRef.current) {
            return;
          }
          this.valueRef.current.innerText = percentageObj.percentage;
        }
      })
      .add({
        targets: this.valueRef.current,
        easing: "easeInOutQuad",
        duration,
        color: GetTextColorForValue(this.props.metric.digits_goal)
      });

    let noSquareToUpdate = GetNumberOfSquareFor(
      this.props.metric.digits_goal,
      100,
      NO_SQUARE
    );
    if (noSquareToUpdate > NO_SQUARE) {
      noSquareToUpdate = NO_SQUARE;
    }

    if (this.squareContainerRef.current) {
      for (let i = 0; i < NO_SQUARE; i++) {
        let backgroundColor = "rgba(0, 0, 0, .3)";
        if (noSquareToUpdate-- > 0) {
          backgroundColor = GetTextColorForValue(this.props.metric.digits_goal);
        }

        animation.add(
          {
            targets: this.squareContainerRef.current.childNodes[i]
              .childNodes[0],
            height: "100%",
            background: backgroundColor
          },
          Math.floor(duration / 5)
        );
      }
    }
  }
  showIndicators() {
    let squares = [];
    for (let i = 0; i < NO_SQUARE; i++) {
      squares.push(
        <li className={styles.square} key={i}>
          <div className={styles.filling} key={i}></div>
        </li>
      );
    }

    return (
      <ul className={styles.square_container} ref={this.squareContainerRef}>
        {squares}
      </ul>
    );
  }
  handleClickTile = e => {
    if (this.props.metricGraphOn || this.props.metricAnimationIsRunning) {
      return false;
    }
    this.props.showHideMetric(true);
    this.props.setAnimationRunning(true);
    const childPos = this.contentRef.current.getBoundingClientRect();

    const scaleFactor = 1.2;
    const scaleFactorOffset =
      Math.abs(scaleFactor * document.documentElement.clientWidth) / 100;
    const top = this.wrapperRef.current.offsetTop - scaleFactorOffset;

    this.wrapperChartRef.current.style.height = `${childPos.height}px`;
    this.wrapperChartRef.current.style.left = `${childPos.left}px`;
    this.wrapperChartRef.current.style.right = `${childPos.right}px`;
    this.wrapperChartRef.current.style.top = `${top}px`;
    this.wrapperChartRef.current.style.width = `${childPos.width}px`;
    this.wrapperChartRef.current.style.display = "block";
    this.contentRef.current.classList.add(styles.scale_up);

    anime
      .timeline({
        targets: this.wrapperChartRef.current,
        easing: "easeInOutQuad",
        duration: 250,
        opacity: 1.0,
        complete: () => {
          this.contentRef.current.classList.remove(styles.scale_up);
          this.props.setAnimationRunning(false);
        }
      })
      .add(
        {
          targets: this.contentRef.current.childNodes,
          opacity: 0
        },
        0
      )
      .add(
        {
          targets: this.contentRef.current,
          opacity: 0
        },
        250
      )
      .add(
        {
          targets: this.wrapperRef.current,
          backgroundColor: "rgba(0, 0, 0, 0.0)"
        },
        250
      )
      .add(
        {
          targets: this.props.overlayRef.current,
          zIndex: 1,
          opacity: 0.5
        },
        250
      )
      .add(
        {
          targets: this.wrapperChartRef.current,
          left: "2vw",
          top: "5.0vw",
          width: "45.5vw",
          height: "25.5vw"
        },
        250
      )
      .add(
        {
          targets: this.metricChartRef,
          opacity: 1.0
        },
        300
      );
  };
  handleClickOutside = evt => {
    const rightClick = 3;

    if (
      evt.which !== rightClick &&
      this.props.metricGraphOn &&
      this.wrapperRef.current.style.backgroundColor === "rgba(0, 0, 0, 0)" // detect the only metric open
    ) {
      this.closeTile();
    }
  };
  closeTile = () => {
    if (this.props.metricAnimationIsRunning) {
      return;
    }
    this.props.setAnimationRunning(true);
    const childPos = this.wrapperRef.current.getBoundingClientRect();
    const screenWidth = document.documentElement.clientWidth;
    const resetHeight = (childPos.height / screenWidth) * 100;
    const resetLeft = (childPos.left / screenWidth) * 100;
    const resetRight = (childPos.right / screenWidth) * 100;
    const resetTop = (this.wrapperRef.current.offsetTop / screenWidth) * 100;
    const resetWidth = (childPos.width / screenWidth) * 100;

    const closingAnimation = anime
      .timeline({
        targets: this.wrapperChartRef.current,
        easing: "easeInOutQuad",
        duration: 250,
        height: resetHeight,
        left: resetLeft,
        right: resetRight,
        top: resetTop,
        width: resetWidth,

        complete: () => {
          this.props.showHideMetric(false);
          if (!this.wrapperChartRef.current) {
            return;
          }
          this.wrapperChartRef.current.style.width = "0";
          this.wrapperChartRef.current.style.height = "0";
          this.wrapperChartRef.current.style.display = "none";
        }
      })
      .add({
        targets: this.props.overlayRef.current,
        zIndex: 0,
        opacity: 0.0
      })
      .add(
        {
          targets: this.wrapperChartRef.current,
          opacity: 0.0
        },
        250
      )
      .add(
        {
          targets: this.contentRef.current.childNodes,
          opacity: 1
        },
        0
      )
      .add(
        {
          targets: this.contentRef.current,
          opacity: 1
        },
        100
      )
      .add(
        {
          targets: this.wrapperRef.current,
          backgroundColor: "rgba(247, 247, 247, 1.0)"
        },
        100
      );

    anime({
      targets: this.metricChartRef,
      opacity: 0.0,
      duration: 300,
      complete: () => {
        closingAnimation.play();
        this.props.setAnimationRunning(false);
      }
    });
  };
  componentDidMount() {
    this.runAnimations();
  }
  render() {
    this.runAnimations();
    return (
      <div
        className={`${styles.wrapper} ${styles[this.props.column_type]} ${
          this.props.hide === true ? styles.hide : ""
        } ${this.props.metricGraphOn === true ? styles.disabled_click : ""}`}
        ref={this.wrapperRef}
        onClick={
          this.props.nielsenON || this.props.metricGraphOn
            ? this.handleClickTile
            : ""
        }
      >
        <div
          className={`${styles.content} ${
            !this.props.nielsenON ? styles.disabled : ""
          }`}
          ref={this.contentRef}
        >
          <div className={styles.title} ref={this.titleRef}>
            {this.props.metric.title}
          </div>
          <div
            className={`${styles.value} ${
              styles[this.props.metric.title.toLowerCase()]
            }`}
            ref={this.valueRef}
          >
            {this.props.metric.value}
          </div>
          {this.showIndicators()}
        </div>

        <div className={styles.wrapper_chart} ref={this.wrapperChartRef}>
          <MetricChart
            ref={el => (this.metricChartRef = ReactDOM.findDOMNode(el))}
            closeTile={this.closeTile}
            metric={this.props.metric}
          ></MetricChart>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricGraphOn: state.metricGraphOn,
    path: state.path,
    metricAnimationIsRunning: state.metricAnimationIsRunning
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showHideMetric: isShow => {
      dispatch({ type: SHOW_HIDE_METRIC_GRAPH, showMetricGraph: isShow });
    },
    setAnimationRunning: isRunning => {
      dispatch({ type: METRIC_ANIMATIONS_RUNNING, isRunning });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(MetricTile));
