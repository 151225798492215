import { Router } from "@reach/router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import Home from "./components/Home";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Store from "./store/";
require("typeface-roboto");
require("typeface-roboto-condensed");

ReactDOM.render(
  <Provider store={Store}>
    {/* <App /> */}

    <Router id="router">
      <Home path="/Auto" />
      <Home path="/CPG" />
      <App path="/" />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
