import React, { Component } from "react";
import annaImg from "../assets/anna.png";
import cordcutterCircle from "../assets/cordcutter_circle.png";
import anna_34_age from "../assets/meet_anna_34.png";
import ontheGo from "../assets/on_the_go_mom.png";
import socialMediaUser from "../assets/social_media_circle.png";
import AnnaCar from "./AnnaCar";
import styles from "./Campaign.module.scss";
class Campaign extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header>
            <h1>Meet Anna</h1>
          </header>

          <div className={styles.illustrations}>
            <div className={styles.column_side}>
              <div className={styles.top}>
                <img className={styles.empty_circle} src={ontheGo} alt=""></img>
                <h2>On-the-Go Mom</h2>
              </div>
              <div className={styles.bottom}>
                <img
                  className={styles.empty_circle}
                  src={anna_34_age}
                  alt=""
                ></img>
                <h2>Millennial</h2>
              </div>
            </div>
            <div className={styles.column_middle}>
              <img className={styles.anna_logo} src={annaImg} alt=""></img>
            </div>
            <div className={styles.column_side}>
              <div className={styles.top}>
                <img
                  className={styles.empty_circle}
                  src={cordcutterCircle}
                  alt=""
                ></img>
                <h2>Cord-Cutter</h2>
              </div>
              <div className={styles.bottom}>
                <img
                  className={styles.empty_circle}
                  src={socialMediaUser}
                  alt=""
                ></img>
                <h2>Social Media User</h2>
              </div>
            </div>
          </div>
        </div>
        <AnnaCar></AnnaCar>
      </div>
    );
  }
}

export default Campaign;
