import anime from "animejs/lib/anime.es.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import dotteArrowFrequency from "../assets/dottedarrow_frequency_2.png";
import dotteArrowTarget from "../assets/dottedarrow_ontarget_1.png";
import dotteArrowRoi from "../assets/dottedarrow_roi_4.png";
import dotteArrowSafety from "../assets/dottedarrow_safety_3.png";
import { GetTextColorForValue } from "../utils/colors";
import MetricTile from "./MetricTile";
import styles from "./MetricTiles.module.scss";

// Metrics to keep when Nielsen Off
const ENABLED_METRICS = ["On-Target %", "Brand Safety %", "ROAS", "Frequency"];
class MetricTiles extends Component {
  constructor(props) {
    super(props);
    this.progressRef = React.createRef();
    this.percentageTitleRef = React.createRef();
    this.goalTitleRef = React.createRef();
    this.graphPlaceholderRef = React.createRef();
    this.overlayRef = React.createRef();
    this.state = { checked: true };
  }
  handleChange = checked => {
    this.setState({ checked });
  };
  getGoalReached() {
    if (!this.props.selectedAllocationsForPreset) {
      return "0%";
    }

    return this.props.selectedAllocationsForPreset.goal_reached;
  }
  showPoorResults() {
    // this Allocation is the bad one
    const { selectedPreset = null } = this.props;
    // TODO: might have a different preset name for CPG content
    let hide = styles.hide;
    if (
      selectedPreset &&
      selectedPreset.title === "Heavy Social" &&
      this.state.checked
    ) {
      hide = "";
    }

    return (
      <div className={`${styles.poor_results} ${hide}`}>
        Poor Results. Try Again.
      </div>
    );
  }

  animations() {
    const currentColor = GetTextColorForValue(this.getGoalReached().digits);
    let percentageObj = {
      goal_percentage: this.percentageTitleRef.current
        ? this.percentageTitleRef.current.innerText
        : "0%"
    };

    anime
      .timeline({
        targets: this.progressRef.current,
        width: `${this.getGoalReached().text}`,
        easing: "easeInOutQuad",
        duration: 400,
        background: `${currentColor}`
      })
      .add(
        {
          targets: percentageObj,
          goal_percentage: this.getGoalReached().text,
          easing: "easeInOutQuad",
          duration: 400,
          round: 1,
          update: () => {
            if (this.percentageTitleRef.current) {
              this.percentageTitleRef.current.innerText =
                percentageObj.goal_percentage;
            }
          }
        },
        0
      )
      .add(
        {
          targets: this.percentageTitleRef.current,
          easing: "easeInOutQuad",
          duration: 400,
          color: currentColor
        },
        0
      )
      .add(
        {
          targets: this.goalTitleRef.current,
          easing: "easeInOutQuad",
          duration: 400,
          color: currentColor
        },
        0
      );
  }
  showAudience() {
    const audiences = this.props.selectedAllocationsForPreset.audience;
    const metrics = audiences.map((metric, index) => {
      return (
        <MetricTile
          key={index}
          nielsenON={this.state.checked}
          metric={metric}
          hide={
            !this.state.checked && !ENABLED_METRICS.includes(metric.title)
              ? true
              : false
          }
          column_type="column_audience"
          overlayRef={this.overlayRef}
        ></MetricTile>
      );
    });

    return (
      <div
        className={`${styles.audience_column} ${this.hideContentNielsenOff()}`}
      >
        <h2 className={this.hideContentNielsenOff()}>Audience</h2>
        <div className={styles.metrics_column} key={"Audience"}>
          {metrics}
        </div>
      </div>
    );
  }
  showBrandSafety() {
    const safety = this.props.selectedAllocationsForPreset.brand_safety;
    const metrics = safety.map(metric => {
      return (
        <MetricTile
          key={metric.title}
          nielsenON={this.state.checked}
          hide={
            !this.state.checked && !ENABLED_METRICS.includes(metric.title)
              ? true
              : false
          }
          metric={metric}
          column_type="column_safety"
          overlayRef={this.overlayRef}
        ></MetricTile>
      );
    });

    return (
      <div
        className={`${styles.safety_column} ${this.hideContentNielsenOff()}`}
      >
        <h2 className={this.hideContentNielsenOff()}>Verification</h2>
        <div className={styles.metrics_column}>{metrics}</div>
      </div>
    );
  }
  showRoi() {
    const roi = this.props.selectedAllocationsForPreset.roi;
    const metrics = roi.map(metric => {
      return (
        <MetricTile
          key={metric.title}
          nielsenON={this.state.checked}
          hide={
            !this.state.checked && !ENABLED_METRICS.includes(metric.title)
              ? true
              : false
          }
          metric={metric}
          column_type="column_roi"
          overlayRef={this.overlayRef}
        ></MetricTile>
      );
    });

    return (
      <div
        className={`${styles.roi_column} ${
          styles["no_border"]
        } ${this.hideContentNielsenOff()}`}
      >
        <h2 className={this.hideContentNielsenOff()}>ROAS</h2>
        <div className={styles.metrics_column}>{metrics}</div>
      </div>
    );
  }
  handleClickMetric = element => {
    // console.log(element.target.getBoundingClientRect());
    // const elementLayout = element.target.getBoundingClientRect();
    // console.log("type of elementLayout.height", typeof elementLayout.height);
    // this.graphPlaceholderRef.current.style.bottom = `${elementLayout.bottom}px`;
    // this.graphPlaceholderRef.current.style.height = `${elementLayout.height}px`;
    // this.graphPlaceholderRef.current.style.left = `${elementLayout.left}px`;
    // this.graphPlaceholderRef.current.style.right = `${elementLayout.right}px`;
    // this.graphPlaceholderRef.current.style.top = `${elementLayout.top}px`;
    // this.graphPlaceholderRef.current.style.width = `${elementLayout.width}px`;
    // // this.graphPlaceholderRef.current.style.x = `${elementLayout.x}px`;
    // // this.graphPlaceholderRef.current.style.y = `${elementLayout.y}px`;
    // console.log(
    //   "elementLayout.width.toString()",
    //   elementLayout.width.toString()
    // );
    // console.log(
    //   "element.target.getBoundingClientRect(",
    //   this.graphPlaceholderRef.current.getBoundingClientRect()
    // );
    // anime({
    //   targets: this.graphPlaceholderRef.current,
    //   easing: "easeInOutQuad",
    //   left: "10vw"
    //   left: "10vw"
    //   duration: 400,
    //   opacity: 1.0
    // });
  };
  hideContentNielsenOff() {
    return !this.state.checked ? styles.hide : "";
  }

  showNielsenOffInfo() {
    if (this.state.checked) {
      return;
    }

    return (
      <div className={styles.nielsen_wrapper_off}>
        <div className={styles.nielsen_info_off}>
          Not Available from a Single Source
        </div>
        <img
          className={styles.dottearrw_ontarget}
          src={dotteArrowTarget}
          alt=""
        ></img>
        <img
          className={styles.dottearrw_frequency}
          src={dotteArrowFrequency}
          alt=""
        ></img>
        <img
          className={styles.dottearrw_safety}
          src={dotteArrowSafety}
          alt=""
        ></img>
        <img className={styles.dottearrw_roi} src={dotteArrowRoi} alt=""></img>
      </div>
    );
  }
  componentDidMount() {
    this.animations();
  }
  render() {
    this.animations();
    return (
      <div className={styles.wrapper}>
        <div className={styles.overlay} ref={this.overlayRef}></div>
        <h1 className={styles.title}>
          {this.props.path === "auto" ? `What Matt Sees` : `What Kate Sees`}
        </h1>
        <div className={styles.switch_wrapper}>
          <div className={styles.nielsen}>Nielsen</div>
          <label className={styles.switch} htmlFor="material-switch">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#c3e3fc"
              onHandleColor="#00CDEF"
              height={20}
              width={48}
              handleDiameter={26}
              boxShadow={"0px 1px 3px -0.0px rgba(0, 0, 0, .75)"}
              onChange={this.handleChange}
              checked={this.state.checked}
              id="material-switch"
            />
          </label>
        </div>
        <div
          className={`${
            styles.progress_wrapper
          } ${this.hideContentNielsenOff()}`}
        >
          <div className={styles.goal_title} ref={this.goalTitleRef}>
            Goals Reached
          </div>
          <div className={styles.progress_bar}>
            <div
              className={`${styles.progress} progress`}
              ref={this.progressRef}
            ></div>
            <div className={styles.shadow}></div>
          </div>
          <div className={styles.value} ref={this.percentageTitleRef}>
            0
          </div>
        </div>
        {this.showNielsenOffInfo()}
        {this.showPoorResults()}
        <div className={styles.metrics_wrapper}>
          {this.showAudience()}
          {this.showBrandSafety()}
          {this.showRoi()}
        </div>
        <div
          className={styles.graph_placeholder}
          ref={this.graphPlaceholderRef}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedAllocationsForPreset: state.selectedAllocationsForPreset,
    selectedPreset: state.selectedPreset,
    metricGraphOn: state.metricGraphOn,
    path: state.path
  };
};

export default connect(mapStateToProps)(MetricTiles);
