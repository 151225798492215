import React, { Component } from "react";
import { connect } from "react-redux";
import annaFlower from "../assets/anna_flower.png";
import annaFlowerCPG from "../assets/cpg_annasconsidering_1.png";
import styles from "./AnnaCar.module.scss";
class AnnaCar extends Component {
  render() {
    let anna;
    let sedan_cpg;
    let text1;
    let text2;
    let flower;
    if (this.props.path === "auto") {
      anna = annaFlower;
      sedan_cpg = styles.sedan;
      text1 = "Anna's considering upgrading her";
      text2 = "sedan to a crossover SUV.";
      flower = styles.flower;
    } else {
      anna = annaFlowerCPG;
      sedan_cpg = styles.cpg_content;
      text1 = "Anna's considering upgrading";
      text2 = "her kids' toothbrushes.";
      flower = styles.flower_cpg;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header></header>
          <img className={flower} src={anna} alt=""></img>
          <div className={sedan_cpg} alt="">
            <div className={styles.context}>
              {text1}
              <br />
              {text2}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    path: state.path
  };
};

export default connect(mapStateToProps)(AnnaCar);
