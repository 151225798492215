import React, { Component } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { GRAPH_TYPE } from "../data";
import GradientSVG from "./GradientSVG";
import styles from "./MetricChart.module.scss";
class MetricChart extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }
  showChart() {
    let graph;
    if (!this.props.metric.detail) {
      return;
    }

    if (this.props.metric.detail.type === GRAPH_TYPE.CIRCULAR) {
      graph = this.props.metric.detail.graph.map((data, index) => {
        let indexCircle = `circle${index}_${this.props.metric.classTitle}`;

        return (
          <div className={`${styles.circle} ${styles[indexCircle]}`}>
            <GradientSVG
              startColor={data.gradient.start}
              endColor={data.gradient.end}
              idCSS={`${this.props.metric.classTitle}${index}`}
              idShadow={`${this.props.metric.classTitle}_shadow${index}`}
              rotation={90}
              index={index}
            ></GradientSVG>

            <CircularProgressbar
              value={data.value}
              idCSS="gradient_circle"
              styles={buildStyles({
                strokeLinecap: "round",
                textColor: data.color
              })}
              text={`${data.value}${data.unit}`}
            ></CircularProgressbar>
            <img className={styles.icon_plateform} src={data.icon} alt=""></img>
            <div className={styles.title} style={{ color: data.color }}>
              {data.title}
            </div>
          </div>
        );
      });
    } else if (this.props.metric.detail.type === GRAPH_TYPE.BAR) {
      graph = this.props.metric.detail.graph.map((data, index) => {
        // const this.props.metric.detail.max_value = 80%
        // bar_value = x

        return (
          <div className={styles.bars_segment}>
            <div className={styles.bars_wrapper}>
              {data.values.map(bar => {
                const maxHeightPercentage = 80; // leave space for small icons
                let height =
                  (maxHeightPercentage * parseFloat(bar.value)) /
                  this.props.metric.detail.max_value;
                const value = isNaN(parseFloat(bar.value))
                  ? "N/A"
                  : `${bar.value}${data.unit}`;
                return (
                  <div
                    className={`${styles.bar} ${
                      data.values.length === 1 ? styles.single : ""
                    }`}
                  >
                    {bar.icon && (
                      <img
                        className={styles.icon_plateform}
                        src={bar.icon}
                        alt=""
                      ></img>
                    )}

                    {bar.text && (
                      <div
                        className={styles.bar_text} // GIVT SIVT
                        style={{ color: bar.color }}
                      >
                        {bar.text}
                      </div>
                    )}

                    <div
                      className={styles.bar_progress}
                      style={{
                        height: `${height}%`,
                        background: bar.bar_color
                      }}
                    ></div>

                    <div
                      className={styles.bar_value}
                      style={{ color: bar.color }}
                    >
                      {value}
                    </div>
                  </div>
                );
              })}
            </div>
            {data.title && (
              <div className={styles.title} style={{ color: data.color }}>
                {data.title}
              </div>
            )}
            {this.props.metric.detail.platform_logo && (
              <img
                className={styles.platform_logo}
                src={this.props.metric.detail.platform_logo[index]}
                alt=""
              ></img>
            )}
          </div>
        );
      });
    } else if (this.props.metric.detail.type === GRAPH_TYPE.TIME) {
      graph = (
        <div className={styles.pie_time_wrapper}>
          <div className={styles.f_times}>
            <div className={styles.f_icon}></div>
            {this.props.metric.detail.f_values.values.map(info => {
              return (
                <div className={styles.clock_female_wrapper}>
                  <div
                    className={styles.clock_title}
                    style={{ color: this.props.metric.detail.f_values.color }}
                  >
                    {info.title}
                  </div>
                  <div className={styles.clock_female}>
                    <CircularProgressbar
                      value={40}
                      strokeWidth={50}
                      text={`${info.value}${this.props.metric.detail.unit}`}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        backgroundColor: "#ffffff"
                      })}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.separator}></div>
          <div className={styles.m_times}>
            <div className={styles.m_icon}></div>
            {this.props.metric.detail.m_values.values.map(info => {
              return (
                <div className={styles.clock_male_wrapper}>
                  <div
                    className={styles.clock_title}
                    style={{ color: this.props.metric.detail.m_values.color }}
                  >
                    {info.title}
                  </div>
                  <div className={styles.clock_male}>
                    <CircularProgressbar
                      value={40}
                      strokeWidth={50}
                      text={`${info.value}${this.props.metric.detail.unit}`}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        backgroundColor: "#ffffff"
                      })}
                    />
                  </div>
                </div>
              );
            })}
            <div className={styles.empty_clock}></div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.chart_wrapper}>
        <div className={styles.main_title}>
          {this.props.metric.detail.title}
        </div>
        <div className={styles.graph_wrapper}>{graph}</div>
      </div>
    );
  }
  showLegend() {
    if (!this.props.metric.detail || !this.props.metric.detail.legend) {
      return;
    }

    const legendContent = this.props.metric.detail.legend.map(info => {
      return (
        <div className={styles.legend} style={{ color: info.color }}>
          <span
            className={styles.dot}
            style={{ background: info.color }}
          ></span>
          {info.text}
        </div>
      );
    });

    return (
      <div className={styles.legend_wrapper}>
        <div className={styles.legend_content}>{legendContent}</div>
      </div>
    );
  }
  render() {
    return (
      <div className={styles.wrapper} ref={this.wrapperRef}>
        <button
          className={styles.close}
          onClick={this.props.closeTile}
        ></button>
        {this.showChart()}
        {this.showLegend()}
      </div>
    );
  }
}

export default MetricChart;
