import React, { Component } from "react";
import familytvNetwork from "../assets/familytvnetwork_circle.png";
import programmaticPlatform from "../assets/programmaticplatform_circle.png";
import socialSharingSite from "../assets/socialsharingsite_circle.png";
import styles from "./PlaceholderAllocations.module.scss";

class PlaceholderAllocations extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.budget_wrapper}>
          <div className={styles.title}>Total Budget</div>
          <div className={styles.value}>$3 Million</div>
        </div>
        <div className={styles.circle_container}>
          <img
            className={styles.circle}
            src={programmaticPlatform}
            alt=""
          ></img>
          <img className={styles.circle} src={familytvNetwork} alt=""></img>
          <img className={styles.circle} src={socialSharingSite} alt=""></img>
        </div>
      </div>
    );
  }
}

export default PlaceholderAllocations;
