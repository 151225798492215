import { navigate } from "@reach/router";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RESET_DATA, SCROLL_TO_INDEX } from "../actions/ActionTypes";
import nielsen from "../assets/nielsen_header.png";
import styles from "./Header.module.scss";
class Header extends Component {
  onClickHome = () => {
    this.props.forceReset();
    navigate("/", { replace: true });
  };
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <h1 onClick={this.onClickHome}>
            <img className={styles.nielsen} src={nielsen} alt=""></img>
            Plan, Act, Measure
          </h1>
          <div className={styles.divider}></div>
        </div>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    forceScrollToSection: section => {
      dispatch({ type: SCROLL_TO_INDEX, lastForceScrollingIndex: section });
    },
    forceReset: () => {
      dispatch({ type: RESET_DATA });
    }
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Header);
