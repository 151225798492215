import React, { Component } from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import outcome_anna_cpg from "../assets/cpg_theoutcome_anna.png";
import outcome_boardroom_cpg from "../assets/cpg_theoutcome_boardroom.png";
import outcome_anna from "../assets/outcome_anna.png";
import outcome_boardroom from "../assets/theoutcome_boardroom.png";
import styles from "./Outcome.module.scss";

class Outcome extends Component {
  constructor() {
    super();
    this.state = {
      isGoalsOpen: false,
      width: 0
    };
    this.childRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }
  onWindowResize = () => {
    this.fakeDrag();
  };
  fakeDrag = () => {
    const clickEvent = document.createEvent("MouseEvents");
    clickEvent.initEvent("mousedown", true, true);
    this.childRef.current.dispatchEvent(clickEvent);
  };
  onStart = () => {
    this.setState({ activeDrags: this.state.activeDrags + 1 });
  };
  onStop = () => {
    this.setState({ activeDrags: this.state.activeDrags - 1 });
  };
  onDrag = (e, data) => {
    this.setState({
      width: Math.abs(data.x * 100) / document.documentElement.clientWidth
    });
  };
  getDraggableKey() {
    if (!this.props.selectedPreset) {
      return "";
    }

    return this.props.selectedPreset.title;
  }
  render() {
    const dragHandlers = {
      onStart: this.onStart,
      onStop: this.onStop,
      onDrag: this.onDrag
    };
    let outcomeImage;
    if (this.props.path === "auto") {
      outcomeImage = outcome_anna;
    } else {
      outcomeImage = outcome_anna_cpg;
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.draggable_wrapper}>
          <img className={styles.image} src={outcomeImage} alt="" />
          <Draggable
            className={styles.draggable}
            axis="x"
            {...dragHandlers}
            bounds={"parent"}
            key={this.getDraggableKey()}
          >
            <div className={styles.drag} ref={this.childRef}>
              <img
                className={styles.boardroom}
                src={
                  this.props.path === "auto"
                    ? outcome_boardroom
                    : outcome_boardroom_cpg
                }
                style={{
                  left: `calc(95% - ${this.state.width}%)`
                }}
                alt=""
              ></img>
              <div className={styles.bar}>
                <div className={styles.dragger}></div>
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPreset: state.selectedPreset,
    path: state.path
  };
};
export default connect(mapStateToProps)(Outcome);
