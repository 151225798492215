export const COLORS = {
  GREEN: "#48c966",
  ORANGE: "#f07a3f",
  RED: "#f44036"
};

export const GRAPH_COLORS = {
  BLUE: "#37c5f9",
  LIGHT_GREEN: "#28CEA4",
  DARK_GREEN: "#67cbcb"
};

export const BAR_COLORS = {
  BLUE: "linear-gradient(to top, #00c5ff 0%,#00e4ff 100%)",
  LIGHT_GREEN: "linear-gradient(to top, #39edbf 0%,#6bf7e1 100%)",
  DARK_GREEN: "linear-gradient(to top, #35c2b0 0%,#49e3d0 100%)"
};

export const COLORS_GRADIENT = {
  BLUE_START: "#00c5ff",
  BLUE_END: "#00e4ff",
  LIGHT_GREEN_START: "#39edbf",
  LIGHT_GREEN_END: "#6bf7e1",
  DARK_GREEN_START: "#35c2b0",
  DARK_GREEN_END: "#49e3d0"
};

export const GetTextColorForValue = value => {
  if (value <= 33) {
    return COLORS.RED;
  } else if (value <= 66) {
    return COLORS.ORANGE;
  } else {
    return COLORS.GREEN;
  }
};

export const GetNumberOfSquareFor = (curValue, goalValue, nbSquare) => {
  return Math.floor((curValue * nbSquare) / goalValue);
};
