import {
  METRIC_ANIMATIONS_RUNNING,
  RESET_DATA,
  SCROLL_TO_INDEX,
  SELECT_PRESET,
  SELECT_SECTION,
  SET_PATH,
  SHOW_HIDE_METRIC_GRAPH
} from "../actions/ActionTypes";
import {
  ALLOCATIONS_AUTO,
  ALLOCATIONS_CPG,
  GOALS_DATA,
  STRATEGIES_PRESETS
} from "../data";

const Sections = [
  "Meet Anna",
  "Meet Matt",
  "Matt's Allocations",
  "Impact On Anna",
  "The Outcome"
];

const SectionCPG = [
  "Meet Anna",
  "Meet Kate",
  "Kate's Allocations",
  "Impact On Anna",
  "The Outcome"
];

const initialState = {
  sections: Sections,
  selectedSection: 0,
  lastForceScrollingIndex: null,
  goals: GOALS_DATA,
  presets: STRATEGIES_PRESETS,
  selectedPreset: null,
  allocations: ALLOCATIONS_AUTO,
  selectedAllocationsForPreset: null,
  metricGraphOn: false,
  path: null,
  metricAnimationIsRunning: false
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case SELECT_SECTION:
      newState.selectedSection = action.selectedSection;
      break;

    case SELECT_PRESET:
      newState.selectedPreset = action.selectedPreset;
      newState.selectedAllocationsForPreset =
        newState.allocations[newState.selectedPreset.id];
      break;

    case SCROLL_TO_INDEX:
      newState.lastForceScrollingIndex = action.lastForceScrollingIndex;
      break;

    case RESET_DATA:
      newState.selectedPreset = null;
      newState.selectedAllocationsForPreset = null;
      break;

    case SHOW_HIDE_METRIC_GRAPH:
      newState.metricGraphOn = action.showMetricGraph;
      break;

    case SET_PATH:
      if (action.path === "/Auto") {
        newState.allocations = ALLOCATIONS_AUTO;
        newState.path = "auto";
        newState.sections = Sections;
      } else {
        newState.allocations = ALLOCATIONS_CPG;
        newState.path = "cpg";
        newState.sections = SectionCPG;
      }
      break;

    case METRIC_ANIMATIONS_RUNNING:
      newState.metricAnimationIsRunning = action.isRunning;
      break;

    default:
      break;
  }
  return newState;
};

export default reducer;
