import React, { Component } from "react";
import styles from "./ProgressBar.module.scss";

class ProgressBar extends Component {
  updateProgress() {
    // const currTime = parseInt(this.state.currentTime, 10);
    const percentage = parseInt(this.props.progressScroll * 100, 10);

    return {
      transition: `width 500ms ease 0s`,
      width: `${percentage}%`
    };
  }
  render() {
    return (
      <div className={`${this.props.className} ${styles.wrapper}`}>
        <div style={this.updateProgress()} className={styles.progress}></div>
      </div>
    );
  }
}

export default ProgressBar;
