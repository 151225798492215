import instagramCircleImage from "./assets/instagram_circle.png";
import nbcLogoPopup from "./assets/metricpopup_logo_familytvnetwork.png";
import tradeDeskLogoPopup from "./assets/metricpopup_logo_programmaticplatform.png";
import instagramLogoPopup from "./assets/metricpopup_logo_socialsharingsite.png";
import nbcCircleImage from "./assets/nbc_circle.png";
import notbrandsafe from "./assets/notbrandsafe.png";
import notinview from "./assets/notinview.png";
import adultcontent_icon from "./assets/popup_brandsafety_adultcontent_icon.png";
import crimecontent_icon from "./assets/popup_brandsafety_crimecontent_icon.png";
import drugcontent_icon from "./assets/popup_brandsafety_drugcontent_icon.png";
import ctvIcon from "./assets/popup_ctv_icon.png";
import desktopIcon from "./assets/popup_desktop_icon.png";
import mobileIcon from "./assets/popup_mobile_icon.png";
import suvIcon from "./assets/popup_suv_icon.png";
import toothBrushInmarkerIcon from "./assets/popup_toothbrush_inmarket_icon.png";
import toothBrushOwnerIcon from "./assets/popup_toothbrush_owner_icon.png";
import vanIcon from "./assets/popup_van_icon.png";
import programmaticPlatform from "./assets/programmaticplatform_circle.png";
import viewed_2x from "./assets/viewed_2x.png";
import viewed_3x from "./assets/viewed_3x.png";
import {
  BAR_COLORS,
  COLORS,
  COLORS_GRADIENT,
  GRAPH_COLORS
} from "./utils/colors";

export const GRAPH_TYPE = {
  CIRCULAR: "CIRCULAR",
  BAR: "BAR",
  TIME: "TIME"
};

export const GOALS_DATA = [
  { title: "Target Audience", value: "Females 25-45" },
  { title: "Effectiveness", value: "600" },
  { title: "ROI", value: "$13" },
  { title: "Incremental Volume", value: "600k" },
  { title: "Audience Reach", value: "55%" },
  { title: "Viewability Goal", value: "50%" },
  { title: "Average Frequency", value: "3x" },
  { title: "On-target Viewability Goal", value: "55%" },
  { title: "On Target", value: "60%" }
];

export const STRATEGIES_PRESETS = [
  {
    title: "Heavy Social",
    description: "Majority of investment to social sites",
    id: "id1",
    indicator: [
      { title: "Video", value: 56 },
      { title: "CTV", value: 0 },
      { title: "Social", value: 44 }
    ],
    budget: [
      { title: "instagram", value: 2000000, image: instagramCircleImage },
      { title: "tradedesk", value: 1000000, image: programmaticPlatform }
    ]
  },
  {
    title: "Even Allocation",
    description: "Even allocation across creatives, placements and devices",
    id: "id2",
    indicator: [
      { title: "Video", value: 34 },
      { title: "CTV", value: 33 },
      { title: "Social", value: 33 }
    ],
    budget: [
      { title: "tradedesk", value: 1000000, image: programmaticPlatform },
      { title: "instagram", value: 1000000, image: instagramCircleImage },
      { title: "nbc", value: 1000000, image: nbcCircleImage }
    ]
  },

  {
    title: "Heavy Video and CTV",
    description: "Majority of investment to video placements",
    id: "id3",
    indicator: [
      { title: "Video", value: 48 },
      { title: "CTV", value: 44 },
      { title: "Social", value: 8 }
    ],
    budget: [
      { title: "instagram", value: 1250000, image: instagramCircleImage },
      { title: "tradedesk", value: 500000, image: programmaticPlatform },
      { title: "nbc", value: 1250000, image: nbcCircleImage }
    ]
  }
];

export const ALLOCATIONS_AUTO = {
  id1: {
    audience: [
      {
        title: "On-Target %",
        value: "35%",
        goal: "50%",
        digits_value: "35",
        digits_goal: "50",
        classTitle: "on_target",
        detail: {
          title: "On-Target Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "40",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "27",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "32",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "48%",
        goal: "75%",
        digits_value: "48",
        digits_goal: "75",
        color: COLORS.GREEN,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 51,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 38,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 29,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 43,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 38,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 51,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 46,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "52%",
        goal: "85%",
        digits_value: "52",
        digits_goal: "85",
        color: COLORS.GREEN,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 56,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 49,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 52,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 33,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 56,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 49,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 52,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "5s",
        goal: "30%",
        digits_value: "5",
        digits_goal: "30",
        color: COLORS.GREEN,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 5 },
              { title: "F 45-44", value: 8 },
              { title: "F 65+", value: 7 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 6 },
              { title: "M 65+", value: 10 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "3x",
        goal: "100%",
        digits_value: "3",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 4,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 4,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "Van Owner On-Target %",
        value: "15%",
        goal: "25%",
        digits_value: "15",
        digits_goal: "25",
        color: COLORS.GREEN,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 40,
          legend: [
            { text: "Mini-Van Owner", color: GRAPH_COLORS.BLUE },
            { text: "In Market for Crossover", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: vanIcon,
                  value: 13,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 38,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 19,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 31,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 21,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 40,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "75%",
        goal: "75%",
        digits_value: "75",
        digits_goal: "75",
        color: COLORS.RED,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              icon: crimecontent_icon,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              value: "30",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              icon: adultcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_START,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              value: "10",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              icon: drugcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "10%",
        goal: "90%",
        digits_value: "10",
        digits_goal: "90",
        color: COLORS.RED,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 8,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 8,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 4,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 4,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 4,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "5.14",
        goal: "40%",
        digits_value: "5.14",
        digits_goal: "40",
        color: COLORS.RED,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 4.01,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 1.13,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,

              unit: ""
            },
            {
              values: [
                {
                  value: 4.01,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,

              unit: ""
            },
            {
              values: [
                {
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,

              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "30,102",
        goal: "25%",
        digits_value: "30,102",
        digits_goal: "25",
        color: COLORS.RED,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 22.815,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "7,287",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "22,815",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "0",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "50%",
    anna_see: [10, 9, 0, 12, 7, 6],
    anna_see_side_icons: {
      9: viewed_3x,
      10: notinview,
      12: notinview,
      7: notbrandsafe,
      6: notbrandsafe
    },
    goal_reached: { text: "50%", digits: "50" }
  },
  id2: {
    audience: [
      {
        title: "On-Target %",
        value: "43%",
        goal: "75%",
        digits_value: "43",
        digits_goal: "75",
        color: COLORS.ORANGE,
        classTitle: "on_target",
        detail: {
          title: "On-Target % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "35",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "59",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "55%",
        goal: "100%",
        digits_value: "55",
        digits_goal: "100",
        color: COLORS.RED,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 60,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 40,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 34,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 50,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 60,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 51,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "62%",
        goal: "90%",
        digits_value: "62",
        digits_goal: "90",
        color: COLORS.ORANGE,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 80,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 32,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 71,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 21,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 15,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 80,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 34,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 62,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "8.5s",
        goal: "50",
        digits_value: "8.5",
        digits_goal: "50",
        color: COLORS.GREEN,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 7 },
              { title: "F 45-44", value: 9 },
              { title: "F 65+", value: 12 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 10 },
              { title: "M 65+", value: 13 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "5x",
        goal: "40%",
        digits_value: "5",
        digits_goal: "40",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 6,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 6,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "Van Owner On-Target %",
        value: "23",
        goal: "33",
        digits_value: "23",
        digits_goal: "33",
        color: COLORS.RED,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 54,
          legend: [
            { text: "Mini-Van Owner", color: GRAPH_COLORS.BLUE },
            { text: "In Market for Crossover", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: vanIcon,
                  value: 27,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 47,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 15,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 52,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 19,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 54,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "90%",
        goal: "90%",
        digits_value: "90",
        digits_goal: "90",
        color: COLORS.RED,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              icon: crimecontent_icon,
              value: "30",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              icon: adultcontent_icon,
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              icon: drugcontent_icon,
              value: "20",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "5%",
        goal: "95%",
        digits_value: "5",
        digits_goal: "95",
        color: COLORS.RED,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 7,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 7,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "9.35",
        goal: "73%",
        digits_value: "9.35",
        digits_goal: "73",
        color: COLORS.RED,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 10.39,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 8.56,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,

              unit: ""
            },
            {
              values: [
                {
                  value: 3.45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,

              unit: ""
            },
            {
              values: [
                {
                  value: 10.39,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,

              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "65,487",
        goal: "54%",
        digits_value: "65,487",
        digits_goal: "54",
        color: COLORS.RED,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 28.668,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "23,589",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "13,230",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "28,668",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "25%",
    anna_see: [10, 9, 0, 1, 5, 6],
    anna_see_side_icons: {
      9: notinview,
      5: notbrandsafe,
      6: notbrandsafe
    },
    goal_reached: { text: "75%", digits: "75" }
  },
  id3: {
    audience: [
      {
        title: "On-Target %",
        value: "60%",
        goal: "100%",
        digits_value: "60",
        digits_goal: "100",
        color: COLORS.RED,
        classTitle: "on_target",
        detail: {
          title: "On-Target % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "62",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "59",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "61",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "65%",
        goal: "100%",
        digits_value: "65",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 60,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 40,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 34,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 50,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 60,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 51,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "70%",
        goal: "100%",
        digits_value: "70",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 84,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 79,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 57,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 66,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 38,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 84,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: "N/A",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 51,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 78,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 69,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "15s",
        goal: "100%",
        digits_value: "15",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 15 },
              { title: "F 45-44", value: 15 },
              { title: "F 65+", value: 14 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 12 },
              { title: "M 65+", value: 13 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "3x",
        goal: "100",
        digits_value: "3",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 3,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "Van Owner On-Target %",
        value: "55%",
        goal: "100%",
        digits_value: "55",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 61,
          legend: [
            { text: "Mini-Van Owner", color: GRAPH_COLORS.BLUE },
            { text: "In Market for Crossover", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: vanIcon,
                  value: 61,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 53,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 53,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: vanIcon,
                  value: 59,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: suvIcon,
                  value: 60,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "98%",
        goal: "100%",
        digits_value: "98",
        digits_goal: "100",
        color: COLORS.ORANGE,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              value: "15",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              icon: crimecontent_icon,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              value: "65",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              icon: adultcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_START,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              value: "20",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              icon: drugcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "1%",
        goal: "100%",
        digits_value: "1",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 2,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 1,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 1,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 0,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "13.75",
        goal: "100%",
        digits_value: "13.75",
        digits_goal: "100",
        color: COLORS.GREEN,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 12.25,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 5.91,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: 8.67,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: 12.25,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "120,830",
        goal: "100%",
        digits_value: "120,830",
        digits_goal: "100",
        color: COLORS.GREEN,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 51.389,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "24,166",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "51,389",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "45,275",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "0%",
    anna_see: [10, 11, 0, 1, 3, 4, 2],
    anna_see_side_icons: {
      10: viewed_3x,
      4: viewed_2x
    },
    goal_reached: { text: "100%", digits: "100" }
  }
};

export const ALLOCATIONS_CPG = {
  id1: {
    audience: [
      {
        title: "On-Target %",
        value: "35%",
        goal: "50%",
        digits_value: "35",
        digits_goal: "50",
        classTitle: "on_target",
        detail: {
          title: "On-Target % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "40",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "27",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "32",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "48%",
        goal: "75%",
        digits_value: "48",
        digits_goal: "75",
        color: COLORS.GREEN,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 51,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 38,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 29,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 43,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 38,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 51,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 46,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "52%",
        goal: "85%",
        digits_value: "52",
        digits_goal: "85",
        color: COLORS.GREEN,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 56,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 49,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 52,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 33,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 56,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 49,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 52,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "5s",
        goal: "30%",
        digits_value: "5",
        digits_goal: "30",
        color: COLORS.GREEN,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 5 },
              { title: "F 45-44", value: 8 },
              { title: "F 65+", value: 7 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 6 },
              { title: "M 65+", value: 10 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "3x",
        goal: "100%",
        digits_value: "3",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 4,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 4,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "eToothbrush On-Target %",
        value: "15%",
        goal: "25%",
        digits_value: "15",
        digits_goal: "25",
        color: COLORS.GREEN,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 40,
          legend: [
            { text: "Electric Toothbrush Owner", color: GRAPH_COLORS.BLUE },
            {
              text: "In Market for Toothbrush",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 13,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 38,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 19,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 31,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 21,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 40,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "75%",
        goal: "75%",
        digits_value: "75",
        digits_goal: "75",
        color: COLORS.RED,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              icon: crimecontent_icon,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              value: "30",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              icon: adultcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_START,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              value: "10",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              icon: drugcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "10%",
        goal: "90%",
        digits_value: "10",
        digits_goal: "90",
        color: COLORS.RED,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 8,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 8,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 4,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 4,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 4,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "5.14",
        goal: "40%",
        digits_value: "5.14",
        digits_goal: "40",
        color: COLORS.RED,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 4.01,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 1.13,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,

              unit: ""
            },
            {
              values: [
                {
                  value: 4.01,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,

              unit: ""
            },
            {
              values: [
                {
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,

              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "30,102",
        goal: "25%",
        digits_value: "30,102",
        digits_goal: "25",
        color: COLORS.RED,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 22.815,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "7,287",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "22,815",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "0",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "50%",
    anna_see: [10, 9, 0, 12, 7, 6],
    anna_see_side_icons: {
      9: viewed_3x,
      10: notinview,
      12: notinview,
      7: notbrandsafe,
      6: notbrandsafe
    },
    goal_reached: { text: "50%", digits: "50" }
  },
  id2: {
    audience: [
      {
        title: "On-Target %",
        value: "43%",
        goal: "75%",
        digits_value: "43",
        digits_goal: "75",
        color: COLORS.ORANGE,
        classTitle: "on_target",
        detail: {
          title: "On-Target % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "35",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "59",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "55%",
        goal: "100%",
        digits_value: "55",
        digits_goal: "100",
        color: COLORS.RED,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 60,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 40,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 34,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 50,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 60,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 51,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "62%",
        goal: "90%",
        digits_value: "62",
        digits_goal: "90",
        color: COLORS.ORANGE,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 80,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 32,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 71,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 21,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 15,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 80,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 0,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 34,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 62,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "8.5s",
        goal: "50",
        digits_value: "8.5",
        digits_goal: "50",
        color: COLORS.GREEN,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 7 },
              { title: "F 45-44", value: 9 },
              { title: "F 65+", value: 12 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 10 },
              { title: "M 65+", value: 13 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "5x",
        goal: "40%",
        digits_value: "5",
        digits_goal: "40",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 6,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 6,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "eToothbrush On-Target %",
        value: "23",
        goal: "33",
        digits_value: "23",
        digits_goal: "33",
        color: COLORS.RED,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 54,
          legend: [
            { text: "Electric Toothbrush Owner", color: GRAPH_COLORS.BLUE },
            {
              text: "In Market for Toothbrush",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 27,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 47,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 15,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 52,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 19,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 54,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "90%",
        goal: "90%",
        digits_value: "90",
        digits_goal: "90",
        color: COLORS.RED,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              icon: crimecontent_icon,
              value: "30",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              icon: adultcontent_icon,
              value: "50",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              icon: drugcontent_icon,
              value: "20",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "5%",
        goal: "95%",
        digits_value: "5",
        digits_goal: "95",
        color: COLORS.RED,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 7,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 7,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "9.35",
        goal: "73%",
        digits_value: "9.35",
        digits_goal: "73",
        color: COLORS.RED,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 10.39,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 8.56,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,

              unit: ""
            },
            {
              values: [
                {
                  value: 3.45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,

              unit: ""
            },
            {
              values: [
                {
                  value: 10.39,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,

              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "65,487",
        goal: "54%",
        digits_value: "65,487",
        digits_goal: "54",
        color: COLORS.RED,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 28.668,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "23,589",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "13,230",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "28,668",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "25%",
    anna_see: [10, 9, 0, 1, 5, 6],
    anna_see_side_icons: {
      9: notinview,
      5: notbrandsafe,
      6: notbrandsafe
    },
    goal_reached: { text: "75%", digits: "75" }
  },
  id3: {
    audience: [
      {
        title: "On-Target %",
        value: "60%",
        goal: "100%",
        digits_value: "60",
        digits_goal: "100",
        color: COLORS.RED,
        classTitle: "on_target",
        detail: {
          title: "On-Target % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Desktop",
              icon: desktopIcon,
              value: "62",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Mobile",
              icon: mobileIcon,
              value: "59",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_END,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "CTV",
              icon: ctvIcon,
              value: "61",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Viewable %",
        value: "65%",
        goal: "100%",
        digits_value: "65",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Viewable % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 60,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          legend: [
            { text: "Viewability by Site", color: GRAPH_COLORS.BLUE },
            { text: "Viewability On-Target", color: GRAPH_COLORS.LIGHT_GREEN }
          ],
          graph: [
            {
              values: [
                {
                  value: 40,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 34,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 50,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 45,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  value: 60,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  value: 51,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Audience Reach %",
        value: "70%",
        goal: "100%",
        digits_value: "70",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Reach % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 84,
          legend: [
            { text: "Desktop", color: GRAPH_COLORS.BLUE },
            { text: "Mobile", color: GRAPH_COLORS.LIGHT_GREEN },
            { text: "CTV", color: GRAPH_COLORS.DARK_GREEN }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 79,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 57,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 66,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 38,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 84,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: "N/A",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 51,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: mobileIcon,
                  value: 78,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                },
                {
                  icon: ctvIcon,
                  value: 69,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      },
      {
        title: "Average Time Spent",
        value: "15s",
        goal: "100%",
        digits_value: "15",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Average Time Spent by Top 5 Demos",
          type: GRAPH_TYPE.TIME,
          unit: "s",
          f_values: {
            color: GRAPH_COLORS.BLUE,
            values: [
              { title: "F 35-44", value: 15 },
              { title: "F 45-44", value: 15 },
              { title: "F 65+", value: 14 }
            ]
          },
          m_values: {
            color: GRAPH_COLORS.LIGHT_GREEN,
            values: [
              { title: "M 35-44", value: 12 },
              { title: "M 65+", value: 13 }
            ]
          }
        }
      },
      {
        title: "Frequency",
        value: "3x",
        goal: "100",
        digits_value: "3",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "frequency",
        detail: {
          title: "Frequency Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 3,
          graph: [
            {
              values: [
                {
                  icon: desktopIcon,
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              title: "Desktop",
              unit: "x"
            },
            {
              values: [
                {
                  icon: mobileIcon,
                  value: 3,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              title: "Mobile",
              unit: "x"
            },
            {
              values: [
                {
                  icon: ctvIcon,
                  value: 3,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              title: "CTV",
              unit: "x"
            }
          ]
        }
      },
      {
        title: "eToothbrush On-Target %",
        value: "55%",
        goal: "100%",
        digits_value: "55",
        digits_goal: "100",
        color: COLORS.GREEN,
        classTitle: "purchase_segments",
        detail: {
          title: "Purchase Segments % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 61,
          legend: [
            { text: "Electric Toothbrush Owner", color: GRAPH_COLORS.BLUE },
            {
              text: "In Market for Toothbrush",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 61,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 42,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 53,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 53,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  icon: toothBrushOwnerIcon,
                  value: 59,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  icon: toothBrushInmarkerIcon,
                  value: 60,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    brand_safety: [
      {
        title: "Brand Safety %",
        value: "98%",
        goal: "100%",
        digits_value: "98",
        digits_goal: "100",
        color: COLORS.ORANGE,
        classTitle: "safety",
        detail: {
          title: "Brand Safety % Breakdown",
          type: GRAPH_TYPE.CIRCULAR,
          graph: [
            {
              title: "Crime Content",
              value: "15",
              unit: "%",
              color: GRAPH_COLORS.BLUE,
              icon: crimecontent_icon,
              gradient: {
                start: COLORS_GRADIENT.BLUE_START,
                end: COLORS_GRADIENT.BLUE_END
              }
            },
            {
              title: "Adult Content",
              value: "65",
              unit: "%",
              color: GRAPH_COLORS.LIGHT_GREEN,
              icon: adultcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.LIGHT_GREEN_START,
                end: COLORS_GRADIENT.LIGHT_GREEN_END
              }
            },
            {
              title: "Drug Content",
              value: "20",
              unit: "%",
              color: GRAPH_COLORS.DARK_GREEN,
              icon: drugcontent_icon,
              gradient: {
                start: COLORS_GRADIENT.DARK_GREEN_START,
                end: COLORS_GRADIENT.DARK_GREEN_END
              }
            }
          ]
        }
      },
      {
        title: "Fraud %",
        value: "1%",
        goal: "100%",
        digits_value: "1",
        digits_goal: "100",
        color: COLORS.ORANGE,
        detail: {
          title: "Fraud % Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 2,
          legend: [
            { text: "General Invalid Traffic", color: GRAPH_COLORS.BLUE },
            {
              text: "Sophisticated Invalid Traffic",
              color: GRAPH_COLORS.LIGHT_GREEN
            }
          ],
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  text: "GIVT",
                  value: 2,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 2,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 1,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 1,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            },
            {
              values: [
                {
                  text: "GIVT",
                  value: 1,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                },
                {
                  text: "SIVT",
                  value: 0,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              unit: "%"
            }
          ]
        }
      }
    ],
    roi: [
      {
        title: "ROAS",
        value: "13.75",
        goal: "100%",
        digits_value: "13.75",
        digits_goal: "100",
        color: COLORS.GREEN,
        detail: {
          title: "ROAS Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 12.25,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: 5.91,
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: 8.67,
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: 12.25,
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      },
      {
        title: "Conversions",
        value: "120,830",
        goal: "100%",
        digits_value: "120,830",
        digits_goal: "100",
        color: COLORS.GREEN,
        detail: {
          title: "Conversions Breakdown",
          type: GRAPH_TYPE.BAR,
          max_value: 51.389,
          platform_logo: [tradeDeskLogoPopup, instagramLogoPopup, nbcLogoPopup],
          graph: [
            {
              values: [
                {
                  value: "24,166",
                  color: GRAPH_COLORS.BLUE,
                  bar_color: BAR_COLORS.BLUE
                }
              ],
              color: GRAPH_COLORS.BLUE,
              unit: ""
            },
            {
              values: [
                {
                  value: "51,389",
                  color: GRAPH_COLORS.LIGHT_GREEN,
                  bar_color: BAR_COLORS.LIGHT_GREEN
                }
              ],
              color: GRAPH_COLORS.LIGHT_GREEN,
              unit: ""
            },
            {
              values: [
                {
                  value: "45,275",
                  color: GRAPH_COLORS.DARK_GREEN,
                  bar_color: BAR_COLORS.DARK_GREEN
                }
              ],
              color: GRAPH_COLORS.DARK_GREEN,
              unit: ""
            }
          ]
        }
      }
    ],
    anna: "0%",
    anna_see: [10, 11, 0, 1, 3, 4, 2],
    anna_see_side_icons: {
      10: viewed_3x,
      4: viewed_2x
    },
    goal_reached: { text: "100%", digits: "100" }
  }
};
