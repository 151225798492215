import React, { Component } from "react";
import { connect } from "react-redux";
import ProgressBar from "./ProgressBar";
import styles from "./Timeline.module.scss";

class Timeline extends Component {
  createButtons() {
    /*
      added previous_sibling_is_selected because css
      don't have previous siblings
    */
    return this.props.sections.map((title, index) => {
      return (
        <button
          onClick={() => {
            this.props.scrollTo(index);
          }}
          className={`${
            index === this.props.selectedSection ? styles.is_selected : ""
          } 
          ${
            index === this.props.selectedSection - 1
              ? styles.previous_sibling_is_selected
              : ""
          } 
          ${this.disabledResultsButton(title) ? styles.disabled : ""} `}
        >{`${title}`}</button>
      );
    });
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log("prevProps.selectedSection", prevProps.selectedSection);
  //   console.log("this.props.selectedSection", this.props.selectedSection);
  //   if (prevProps.selectedSection !== this.props.selectedSection) {
  //     this.props.scrollTo(this.props.selectedSection);
  //   }
  // }

  disabledResultsButton(title) {
    if (this.props.selectedPreset) {
      return this.disabledButtonForBadAllocation(title);
    }

    const resultsButton = [
      "Allocation Results",
      "Impact On Anna",
      "The Outcome"
    ];
    return resultsButton.includes(title);
  }
  disabledButtonForBadAllocation(title) {
    if (this.props.selectedPreset.title !== "Heavy Social") {
      return;
    }

    const resultsButton = ["Impact On Anna", "The Outcome"];
    return resultsButton.includes(title);
  }
  render() {
    return (
      <div className={styles.footer}>
        <ProgressBar
          progressScroll={this.props.progressScroll}
          className={styles.progress_bar}
        ></ProgressBar>
        {this.createButtons()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sections: state.sections,
    selectedSection: state.selectedSection,
    selectedPreset: state.selectedPreset
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     selectSection: section => {
//       dispatch({ type: SCROLL_TO, scrollTo: section });
//     }
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProp
)(Timeline);
