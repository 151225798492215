import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import styles from "./Goals.module.scss";

class Goals extends Component {
  handleClickOutside = evt => {
    this.props.closeGoals();
  };
  showLists() {
    const items = this.props.goals.map(goal => {
      return (
        <span className={styles.goal_item}>
          <span className={styles.goal_item_title}>{goal.title}</span>
          <span className={styles.goal_item_value}>{goal.value}</span>
        </span>
      );
    });
    return (
      <div className={styles.lists}>
        <div className={styles.divider}></div>
        {items}
      </div>
    );
  }
  render() {
    return (
      <div className={`${this.props.className} ${styles.wrapper}`}>
        <div className={styles.title}>Matt's Goals</div>
        {this.showLists()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    goals: state.goals
  };
};

export default connect(mapStateToProps)(onClickOutside(Goals));
