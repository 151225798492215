import React, { Component } from "react";
import { connect } from "react-redux";
import carLogo from "../assets/car_logo_meet_matt.png";
import cpgSales from "../assets/cpg_drive_toothbrush_sales.png";
import kate from "../assets/cpg_kate_meetkate.png";
import palbrush from "../assets/cpg_palbrush_logo_meet_kate.png";
import dataMatt from "../assets/data_meet_matt.png";
import matt from "../assets/matt_meet_matt.png";
import nielsen from "../assets/nielsen_meet_matt.png";
import sales from "../assets/sales_meet_matt.png";
import styles from "./ConsumerStory.module.scss";
import MattCampaign from "./MattCampaign";
class ConsumerStory extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header>
            <h1>{this.props.path === "auto" ? "Meet Matt" : "Meet Kate"}</h1>
          </header>

          <div className={styles.illustrations}>
            <div className={styles.column_side}>
              <div className={styles.top}>
                <img
                  className={styles.empty_circle}
                  src={this.props.path === "auto" ? carLogo : palbrush}
                  alt=""
                ></img>
                <h2>Campaign Manager</h2>
              </div>
              <div className={styles.bottom}>
                <img
                  className={styles.empty_circle}
                  src={dataMatt}
                  alt=""
                ></img>
                <h2>Data Proves Success</h2>
              </div>
            </div>
            <div className={styles.column_middle}>
              <img
                className={styles.anna_logo}
                src={this.props.path === "auto" ? matt : kate}
                alt=""
              ></img>
            </div>
            <div className={styles.column_side}>
              <div className={styles.top}>
                <img
                  className={styles.empty_circle}
                  src={this.props.path === "auto" ? sales : cpgSales}
                  alt=""
                ></img>
                <h2>
                  {this.props.path === "auto"
                    ? "Drive Crossover Sales"
                    : "Drive Toothbrush Sales"}
                </h2>
              </div>
              <div className={styles.bottom}>
                <img className={styles.empty_circle} src={nielsen} alt=""></img>
                <h2>Nielsen DAR and MTA</h2>
              </div>
            </div>
          </div>
        </div>
        <MattCampaign></MattCampaign>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    path: state.path
  };
};

export default connect(mapStateToProps)(ConsumerStory);
