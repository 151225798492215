import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AllocationsPresets.module.scss";
import AnnaSees from "./AnnaSees";
import Goals from "./Goals";
import MetricTiles from "./MetricTiles";
import PersonaGoals from "./PersonaGoals";
import PlaceholderAllocations from "./PlaceholderAllocations";
import PresetSelector from "./PresetsSelector";
class AllocationsPresets extends Component {
  constructor() {
    super();
    this.state = {
      isGoalsOpen: false
    };
  }
  handleClick = () => {
    this.setState({
      isGoalsOpen: true
    });
  };
  closeGoals = () => {
    this.setState({
      isGoalsOpen: false
    });
  };
  showGoals() {
    if (!this.state.isGoalsOpen) {
      return;
    }
    return (
      <Goals className={styles.goals} closeGoals={this.closeGoals}></Goals>
    );
  }
  showAllocations() {
    if (this.props.selectedPreset) {
      return <>{<MetricTiles></MetricTiles>}</>;
    } else {
      return (
        <>
          <h1 className={styles.title}>Budget & Platforms</h1>
          {<PlaceholderAllocations></PlaceholderAllocations>}
        </>
      );
    }
  }
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header className={styles.header}>
            <header className={styles.sub_header}>
              <PersonaGoals></PersonaGoals>
              <div className={styles.allocate}>Time to Allocate</div>
              <div className={styles.strategies}>Strategies</div>
              <div className={styles.divider}></div>
              <PresetSelector></PresetSelector>
            </header>
            {this.showGoals()}
          </header>
          <div className={styles.platforms_anna_wrapper}>
            <div className={styles.budget_platforms}>
              {this.showAllocations()}
            </div>
            <div className={styles.divider}></div>
            <div className={styles.anna_sees}>
              <AnnaSees></AnnaSees>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    goals: state.goals,
    selectedPreset: state.selectedPreset
  };
};

export default connect(mapStateToProps)(AllocationsPresets);
