import anime from "animejs/lib/anime.es.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import annaIcon from "../assets/anna.png";
import cpg_ctv_homedecoratingchannel_video from "../assets/cpg_ctv_homedecoratingchannel_video.png";
import cpg_ctv_morningnewschannel_video from "../assets/cpg_ctv_morningnewschannel_video.png";
import cpg_ctv_nbc_video from "../assets/cpg_ctv_nbc_video.png";
import cpg_ctv_streamingapp_video from "../assets/cpg_ctv_streamingapp_video.png";
import cpg_desktop_instagram_display from "../assets/cpg_desktop_instagram_display.png";
import cpg_desktop_instagram_video from "../assets/cpg_desktop_instagram_video.png";
import cpg_desktop_unsafecars_banner from "../assets/cpg_desktop_unsafecars_banner.png";
import cpg_desktop_vehiclerecall_banner from "../assets/cpg_desktop_vehiclerecall_banner.png";
import cpg_mobileweb_unsafecars_banner from "../assets/cpg_mobileweb_unsafecars_banner.png";
import cpg_mobile_instagram_display from "../assets/cpg_mobile_instagram_display.png";
import cpg_mobile_instagram_video from "../assets/cpg_mobile_instagram_video.png";
import cpg_mobile_nbccom_video from "../assets/cpg_mobile_nbccom_video.png";
import cpg_mobile_vehiclerecall_video from "../assets/cpg_mobile_vehiclerecall_video.png";
import ctv_homedecoratingchannel_video from "../assets/ctv_homedecoratingchannel_video.png";
import ctv_morningnewschannel_video from "../assets/ctv_morningnewschannel_video.png";
import ctv_nbc_video from "../assets/ctv_nbc_video.png";
import ctv_streamingapp_video from "../assets/ctv_streamingapp_video.png";
import desktop_instagram_display from "../assets/desktop_instagram_display.png";
import desktop_instagram_video from "../assets/desktop_instagram_video.png";
import desktop_unsafecars_banner from "../assets/desktop_unsafecars_banner.png";
import desktop_vehiclerecall_banner from "../assets/desktop_vehiclerecall_banner.png";
import mobileweb_unsafecars_banner from "../assets/mobileweb_unsafecars_banner.png";
import mobile_instagram_display from "../assets/mobile_instagram_display.png";
import mobile_instagram_video from "../assets/mobile_instagram_video.png";
import mobile_nbccom_video from "../assets/mobile_nbccom_video.png";
import mobile_vehiclerecall_video from "../assets/mobile_vehiclerecall_video.png";
import styles from "./AnnaSees.module.scss";

class AnnaSees extends Component {
  constructor() {
    super();
    this.annaRef = React.createRef();
    this.small_circles_container = React.createRef();
  }
  setupCircles() {
    let images;
    if (this.props.path === "auto") {
      images = [
        ctv_nbc_video,
        ctv_morningnewschannel_video,
        ctv_homedecoratingchannel_video,
        ctv_streamingapp_video,
        mobile_nbccom_video,
        mobileweb_unsafecars_banner,
        desktop_unsafecars_banner,
        mobile_vehiclerecall_video,
        desktop_vehiclerecall_banner,
        mobile_instagram_display,
        mobile_instagram_video,
        desktop_instagram_video,
        desktop_instagram_display
      ];
    } else {
      images = [
        cpg_ctv_nbc_video,
        cpg_ctv_morningnewschannel_video,
        cpg_ctv_homedecoratingchannel_video,
        cpg_ctv_streamingapp_video,
        cpg_mobile_nbccom_video,
        cpg_mobileweb_unsafecars_banner,
        cpg_desktop_unsafecars_banner,
        cpg_mobile_vehiclerecall_video,
        cpg_desktop_vehiclerecall_banner,
        cpg_mobile_instagram_display,
        cpg_mobile_instagram_video,
        cpg_desktop_instagram_video,
        cpg_desktop_instagram_display
      ];
    }

    let smallIcons;
    if (this.props.selectedAllocationsForPreset) {
      smallIcons = this.props.selectedAllocationsForPreset.anna_see_side_icons;
    }

    return images.map((icon, index) => {
      return (
        <div className={`${styles.circle}`}>
          <img className={styles.icon_circle} index={index} src={icon} alt="" />
          {smallIcons && smallIcons[index] && (
            <img className={styles.icon} src={smallIcons[index]} alt=""></img>
          )}
        </div>
      );
    });
  }
  runAnimations() {
    if (!this.props.selectedAllocationsForPreset) {
      return;
    }

    const duration = 300;
    const animation = anime.timeline({
      targets: this.annaRef.current,
      easing: "easeInOutQuad",
      duration,
      filter: [`grayscale(${this.props.selectedAllocationsForPreset.anna})`]
    });

    if (this.small_circles_container.current) {
      const numberCircle = 13;
      let height;
      let width;
      let margin;
      let grayscale;
      let opacity;
      for (let i = 0; i < numberCircle; i++) {
        if (
          !this.props.selectedAllocationsForPreset ||
          !this.props.selectedAllocationsForPreset.anna_see.includes(i)
        ) {
          height = "3vw";
          width = "3vw";
          margin = "-1.5vw";
          grayscale = "100%";
          opacity = "0.4";
        } else {
          height =
            (window.innerWidth <= 1440 && window.innerHeight <= 900) ||
            (window.innerWidth >= 1920 && window.innerHeight >= 1060)
              ? "7vw"
              : "5vw";
          width =
            (window.innerWidth <= 1440 && window.innerHeight <= 900) ||
            (window.innerWidth >= 1920 && window.innerHeight >= 1060)
              ? "7vw"
              : "5vw";
          margin =
            (window.innerWidth <= 1440 && window.innerHeight <= 900) ||
            (window.innerWidth >= 1920 && window.innerHeight >= 1060)
              ? "-3.5vw"
              : "-2.5vw";
          grayscale = "0%";
          opacity = "1";
        }

        animation.add(
          {
            targets: this.small_circles_container.current.childNodes[i],
            height,
            width,
            margin,
            filter: [`grayscale(${grayscale})`],
            opacity
          },
          0
        );
      }
    }
  }
  componentDidMount() {
    this.runAnimations();
  }
  render() {
    this.runAnimations();
    return (
      <div className={styles.wrapper}>
        <h1 className={styles.title}>What Anna Sees</h1>
        <div className={styles.circle_container}>
          <div
            className={styles.small_circles_container}
            ref={this.small_circles_container}
          >
            {this.setupCircles()}
          </div>
          <div className={styles.anna} ref={this.annaRef}>
            <img src={annaIcon} alt=""></img>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedAllocationsForPreset: state.selectedAllocationsForPreset,
    path: state.path
  };
};

export default connect(mapStateToProps)(AnnaSees);
