import React, { Component } from "react";
import { connect } from "react-redux";
import anna from "../assets/anna.png";
import pathToPurchase from "../assets/annaspathtopurchase.png";
import pathToPurchaseCPG from "../assets/cpg_annaspathtopurchase.png";
import AllocationsDetails from "./AllocationsDetails";
import styles from "./ImpactOnAnna.module.scss";

class ImpactOnAnna extends Component {
  showAllocations() {
    if (this.props.selectedPreset) {
      return <AllocationsDetails></AllocationsDetails>;
    } else {
      return;
    }
  }
  render() {
    let currentPathToPurchase;
    if (this.props.path === "auto") {
      currentPathToPurchase = pathToPurchase;
    } else {
      currentPathToPurchase = pathToPurchaseCPG;
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.title}>
            <img className={styles.anna} src={anna} alt=""></img>
            <h1>Anna's Path to Purchase</h1>
          </div>

          <div className={styles.allocations_wrapper}>
            <img
              className={styles.path_purchase}
              src={currentPathToPurchase}
              alt=""
            ></img>
          </div>
        </div>

        {/* <ConsumerJourney></ConsumerJourney> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPreset: state.selectedPreset,
    path: state.path
  };
};

export default connect(mapStateToProps)(ImpactOnAnna);
