import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AllocationsDetails.module.scss";
class AllocationsDetails extends Component {
  showAllocations() {
    return this.props.selectedAllocationsForPreset.allocations.map(
      platformInfo => {
        return (
          <div className={styles.panel}>
            <img className={styles.logo} src={platformInfo.image} alt=""></img>
            <div className={styles.budget}>{platformInfo.budget}</div>
            <div className={styles.info_wrapper}>
              {this.showCreativeOrPlatform(platformInfo, "creative")}
              <div className={styles.divider}></div>
              {this.showCreativeOrPlatform(platformInfo, "platform")}
            </div>
          </div>
        );
      }
    );
  }
  showCreativeOrPlatform(platformInfo, title) {
    return (
      <div
        className={styles.creative_wrapper}
        key={this.props.selectedAllocationsForPreset}
      >
        <div className={styles.title}>{title}</div>
        <React.Fragment key={this.props.selectedAllocationsForPreset}>
          {platformInfo[title].map(info => {
            return (
              <div className={styles.creative_block}>
                <div className={styles.label}>{info.title}</div>
                <Slider
                  className={styles.slider}
                  defaultValue={info.value}
                  disabled={true}
                  key={info.value}
                />
                <div className={styles.value}>{`${info.value}%`}</div>
              </div>
            );
          })}
        </React.Fragment>
      </div>
    );
  }
  render() {
    return <div className={styles.wrapper}>{this.showAllocations()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    selectedAllocationsForPreset: state.selectedAllocationsForPreset
  };
};

export default connect(mapStateToProps)(AllocationsDetails);
