import React, { Component } from "react";
import styles from "./GradientSVG.module.scss";

class GradientSVG extends Component {
  render() {
    let { startColor, endColor, idCSS, rotation, idShadow } = this.props;
    let gradientTransform = `rotate(${rotation})`;

    return (
      <svg style={{ width: 0 }}>
        <filter id={idShadow} x="-50%" y="-40%" width="200%" height="200%">
          <feDropShadow
            dx="-0"
            dy="0.5"
            stdDeviation="0.5"
            flood-opacity="0.7"
            flood-color="#000000"
          />
        </filter>
        <defs>
          <linearGradient
            className={styles.shadow}
            id={idCSS}
            gradientTransform={gradientTransform}
          >
            <stop offset="0%" stopColor={startColor} />
            <stop offset="100%" stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default GradientSVG;
