import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  SCROLL_TO_INDEX,
  SELECT_SECTION,
  SET_PATH
} from "../actions/ActionTypes";
import AllocationsPresets from "./AllocationsPresets";
import Campaign from "./Campaign";
import ConsumerStory from "./ConsumerStory";
import Header from "./Header";
import styles from "./Home.module.scss";
import ImpactOnAnna from "./ImpactOnAnna";
import Outcome from "./Outcome";
import Footer from "./Timeline";

class Home extends Component {
  constructor() {
    super();

    this.state = {
      currentSection: 0,
      scrollingIndex: 0, // for the next and previous button
      progressScroll: 0,
      storyWrapperChildList: null,
      storyWrapperChildListFlat: []
    };
  }
  componentDidMount() {
    const childItemsNodeList = this.story_wrapper.childNodes;
    const childItems = Array.prototype.slice.call(childItemsNodeList);
    const offset = 10; // offset measure because from rounding
    const storyWrapperHeight =
      window.innerHeight - (5.8 * window.innerWidth) / 100 + offset;
    let allViews = childItems.map(view => {
      if (
        view.childNodes.length > 0 &&
        view.offsetHeight > storyWrapperHeight
      ) {
        const childItems = Array.prototype.slice.call(view.childNodes);

        return [...childItems];
      }

      return view;
    });

    this.setState({
      storyWrapperChildList: childItems,
      storyWrapperChildListFlat: allViews.flat()
    });

    this.story_wrapper.addEventListener("scroll", this.trackScrolling);

    this.props.setPath(this.props.path);
  }
  handleClickPrevious = () => {
    this.state.storyWrapperChildListFlat[
      this.state.scrollingIndex - 1
    ].scrollIntoView({
      behavior: "smooth"
    });
  };
  handleClickNext = () => {
    this.state.storyWrapperChildListFlat[
      this.state.scrollingIndex + 1
    ].scrollIntoView({
      behavior: "smooth"
    });
  };
  trackScrolling = () => {
    const headerHeight =
      (3 / 100) *
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    const childItemsNodeList = this.story_wrapper.childNodes;
    const childItems = Array.prototype.slice.call(childItemsNodeList);
    let itemsAtTop = childItems.filter(el => {
      return (
        el.getBoundingClientRect().top - headerHeight <
        this.story_wrapper.getBoundingClientRect().top
      );
    });
    this.lockScroll(itemsAtTop[itemsAtTop.length - 1]);
    const progressScroll = this.getProgressScroll(
      this.story_wrapper,
      itemsAtTop
    );
    this.updateScrollingIndex();
    this.props.selectSection(itemsAtTop.length - 1);
    this.setState({ progressScroll });
  };
  updateScrollingIndex = () => {
    const headerHeight =
      (3 / 100) *
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let itemsAtTop = this.state.storyWrapperChildListFlat.filter(el => {
      return (
        el.getBoundingClientRect().top - headerHeight <
        this.story_wrapper.getBoundingClientRect().top
      );
    });
    this.setState({ scrollingIndex: itemsAtTop.length - 1 });
  };
  scrollTo = index => {
    this.state.storyWrapperChildList[index].scrollIntoView({
      behavior: "smooth"
    });
  };
  getProgressScroll(storyWrapper, itemsAtTop) {
    const currentSection = itemsAtTop[itemsAtTop.length - 1];
    const currentScrollingSection =
      Math.abs(currentSection.getBoundingClientRect().top) /
      currentSection.getBoundingClientRect().height;
    let percentage =
      (itemsAtTop.length - 1) / this.state.storyWrapperChildList.length +
      (1 / this.state.storyWrapperChildList.length) * currentScrollingSection;
    if (itemsAtTop.length === this.state.storyWrapperChildList.length) {
      percentage = 1;
    }
    return percentage;
  }
  lockScroll(node) {
    // lockScroll on preset page until we select a preset
    const headerHeight = (3 * window.innerWidth) / 100;
    const allocationPresetIndex = 2;
    if (
      node &&
      node.className.includes("AllocationsPresets") &&
      node.getBoundingClientRect().top <= headerHeight &&
      (!this.props.selectedPreset ||
        this.props.selectedPreset.title === "Heavy Social")
    ) {
      this.state.storyWrapperChildList[allocationPresetIndex].scrollIntoView({
        behavior: "auto"
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.lastForceScrollingIndex !== null &&
      prevProps.lastForceScrollingIndex !== this.props.lastForceScrollingIndex
    ) {
      this.scrollTo(this.props.lastForceScrollingIndex);
      this.props.forceScrollToIndex(null);
    }
  }
  render() {
    return (
      <div className={styles.app}>
        <Header></Header>
        <div
          className={styles.story_wrapper}
          ref={ref => {
            this.story_wrapper = ReactDOM.findDOMNode(ref);
          }}
        >
          <Campaign></Campaign>
          <ConsumerStory></ConsumerStory>
          <AllocationsPresets></AllocationsPresets>
          <ImpactOnAnna></ImpactOnAnna>
          <Outcome></Outcome>
        </div>

        <button
          className={`${styles.previous} ${
            this.state.scrollingIndex === 0 ? styles.hide : ""
          }`}
          onClick={this.handleClickPrevious}
        ></button>
        <button
          className={`${styles.next} ${
            this.state.scrollingIndex ===
            this.state.storyWrapperChildListFlat.length - 1
              ? styles.hide
              : ""
          }`}
          onClick={this.handleClickNext}
        ></button>
        <Footer
          currentSection={this.state.currentSection}
          progressScroll={this.state.progressScroll}
          scrollTo={this.scrollTo}
        ></Footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sections: state.sections,
    selectedSection: state.selectSection,
    selectedPreset: state.selectedPreset,
    lastForceScrollingIndex: state.lastForceScrollingIndex
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectSection: section => {
      dispatch({ type: SELECT_SECTION, selectedSection: section });
    },
    forceScrollToIndex: index => {
      dispatch({ type: SCROLL_TO_INDEX, lastForceScrollingIndex: index });
    },
    setPath: path => {
      dispatch({ type: SET_PATH, path });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
