import React, { Component } from "react";
import { connect } from "react-redux";
import { SELECT_PRESET } from "../actions/ActionTypes";
import styles from "./PresetsSelector.module.scss";

class PresetSelector extends Component {
  constructor() {
    super();
    this.state = { justSelected: false };
  }
  handleClickSelection = preset => {
    this.props.selectSection(preset);
    this.setState({ justSelected: true });
  };
  isPresetSelected(preset) {
    if (!this.props.selectedPreset) {
      return false;
    }
    const { title } = this.props.selectedPreset;
    return preset.title === title;
  }
  /* 
    use for hiding the description right after you click on a preset
    is disabled right after you stop hovering in order to show the description again
    if you hover again    
  */
  handleHoverOff = () => {
    this.setState({ justSelected: false });
  };
  showPresets() {
    return this.props.presets.map(preset => {
      return (
        <div
          className={`${styles.preset} ${
            this.isPresetSelected(preset) ? styles.selected : ""
          } ${this.state.justSelected ? styles.just_selected : ""}`}
          onMouseLeave={this.handleHoverOff}
        >
          <div
            className={styles.title}
            onClick={() => this.handleClickSelection(preset)}
          >
            {preset.title}
          </div>

          <div className={styles.description}>
            {preset.description}
            <div className={styles.progress_bar_container}>
              <div className={styles.shadow}></div>
              <div
                className={styles.video_wrapper}
                style={{
                  width: `${preset.indicator[0].value}%`
                }}
              >
                <div className={`${styles.progress_bar} ${styles.left}`}>
                  <div className={styles.progress}>Video</div>
                </div>
                <div className={styles.gap}></div>
              </div>

              {preset.indicator[1].value > 0 && ( // only CTV has a zero percent value from the data
                <div
                  className={styles.ctv_wrapper}
                  style={{
                    width: `${preset.indicator[1].value}%`
                  }}
                >
                  <div className={`${styles.progress_bar} ${styles.middle}`}>
                    <div className={styles.progress}>CTV</div>
                  </div>
                  <div className={styles.gap}></div>
                </div>
              )}

              <div
                className={styles.display_wrapper}
                style={{
                  width: `${preset.indicator[2].value}%`
                }}
              >
                <div className={`${styles.progress_bar} ${styles.right}`}>
                  <div className={styles.progress}>Display</div>
                </div>
              </div>
            </div>
            {this.platformSetup(preset)}
          </div>
        </div>
      );
    });
  }
  platformSetup(preset) {
    const budget = preset.budget;
    budget.sort((b, a) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    const circles = budget.map((platform, index) => {
      let position;
      switch (index) {
        case 0:
          position = styles.left;
          break;
        case 1:
          position = styles.top;
          break;
        case 2:
          position = styles.right;
          break;
        default:
          break;
      }

      return (
        <div
          className={`${styles.circle} ${styles[preset.id]} ${position} ${
            styles[platform.title]
          }`}
          // style={{
          //   transform: `scale(${scale},${scale})`,
          //   zIndex: 2
          // }}
        >
          {/* <img
            className={styles.circle_image}
            src={platform.image}
            alt=""
          ></img> */}
          <div className={styles.budget}>{`$${this.numberWithCommas(
            platform.value
          )}`}</div>
        </div>
      );
    });

    return <div className={styles.circle_container}>{circles}</div>;
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    return <div className={styles.wrapper}>{this.showPresets()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    presets: state.presets,
    selectedPreset: state.selectedPreset,
    allocations: state.allocations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectSection: preset => {
      dispatch({ type: SELECT_PRESET, selectedPreset: preset });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresetSelector);
