import { navigate } from "@reach/router";
import React, { Component } from "react";
import { connect } from "react-redux";
import { SCROLL_TO_INDEX, SELECT_SECTION } from "./actions/ActionTypes";
import styles from "./App.module.scss";
import autoImg from "./assets/landingpage_auto.png";
import cpgImg from "./assets/landingpage_cpg.png";
import logo from "./assets/nielsen_logo_root.jpg";
class App extends Component {
  handleClickAuto = () => {
    navigate(`/Auto`);
  };
  handleClickCPG = () => {
    navigate(`/CPG`);
  };
  render() {
    return (
      <div className={styles.app}>
        <div className={styles.blue}></div>
        <img className={styles.logo} src={logo} alt=""></img>
        <h1 className={styles.title}>Plan, Act, Measure</h1>
        <div className={styles.container_circle}>
          <div
            className={`${styles.circle} ${styles.auto}`}
            onClick={this.handleClickAuto}
          >
            <img src={autoImg} alt=""></img>
            <h1 className={styles.category}>Auto</h1>
          </div>
          <div
            className={`${styles.circle} ${styles.cpg}`}
            onClick={this.handleClickCPG}
          >
            <img src={cpgImg} alt=""></img>
            <h1 className={styles.category}>CPG</h1>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sections: state.sections,
    selectedSection: state.selectSection,
    selectedPreset: state.selectedPreset,
    lastForceScrollingIndex: state.lastForceScrollingIndex
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectSection: section => {
      dispatch({ type: SELECT_SECTION, selectedSection: section });
    },
    forceScrollToIndex: index => {
      dispatch({ type: SCROLL_TO_INDEX, lastForceScrollingIndex: index });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
