import React, { Component } from "react";
import { connect } from "react-redux";
import displays_videos from "../assets/2displays_2videos.png";
import cpg_platforms from "../assets/cpg_kate_campaign_platforms.png";
import platforms_img from "../assets/matt_campaign_platforms.png";
// import walled_img from "../assets/matt_campaign_walled_garden.png";
import media_partners_img from "../assets/mediapartners.png";
import styles from "./MattCampaign.module.scss";
class MattCampaign extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={this.props.path === "auto" ? styles.matt : styles.kate}>
          <div className={styles.title}>
            {this.props.path === "auto" ? `Matt's Campaign` : `Kate's Campaign`}
          </div>
        </div>
        <div className={styles.bubbles}>
          <div className={styles.platforms}>
            <img
              className={styles.platforms_bubble}
              src={this.props.path === "auto" ? platforms_img : cpg_platforms}
              alt=""
            ></img>
            <div className={styles.sub_title}>Platforms</div>
          </div>
          <div className={styles.small_bubbles}>
            <div className={styles.media_partners}>
              <img src={media_partners_img} alt=""></img>
              <div className={styles.sub_title}>Media Partners</div>
            </div>
            <div className={styles.bubble}>
              <img src={displays_videos} alt=""></img>
              <div className={styles.sub_title}>Creatives</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    path: state.path
  };
};
export default connect(mapStateToProps)(MattCampaign);
